import React, { useLayoutEffect} from 'react';
import ScrollToTop from '../ScrollToTop';
import './Lunch.css'
import Submenu from '../SubMenu';
import Appetizers from './LunchItems/Appetizers';
import SoupSalads from './LunchItems/SoupSalad';
import Bagels from './LunchItems/Bagel';
import Sandwiches from './LunchItems/Sandwiches';
import Burgers from './LunchItems/Burgers';
import Wraps from './LunchItems/Wraps';
import Quesadillas from './LunchItems/Quesadillas';
import Dinner from './LunchItems/Dinner';
import Sides from './LunchItems/Sides';

function Lunch() {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className='lunchMain'>
            <ScrollToTop />
            <Submenu />
            <h1 className='menuTitle'>Lunch/Dinner Menu</h1>
            <Appetizers />
            <SoupSalads />
            <Bagels />
            <Sandwiches />
            <Burgers />
            <Wraps />
            <Quesadillas />
            <Dinner />
            <Sides />
        </div>
    )
}

export default Lunch