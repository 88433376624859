import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import "../App.css";

function SubMenu() {

  const [path, setPath] = useState('')

  useEffect (() => {
    setPath(window.location.pathname)
  })

  return (
    <div className='btn'>
      <Link to='/breakfast' className={path === '/breakfast' ? 'active' : 'link'}>Breakfast</Link>
      <Link to='/lunch' className={path === '/lunch' ? 'active' : 'link'}>Lunch / Dinner</Link>
      <Link to='/kids' className={path === '/kids' ? 'active' : 'link'}>Kids</Link>
    </div>
    
  )
}

export default SubMenu