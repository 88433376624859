import React, { useLayoutEffect} from 'react';
import ScrollToTop from '../ScrollToTop';
import Submenu from '../SubMenu';
import './Kids.css'
import KidsBreakfast from './KidsItems/KidsBreakfast';
import KidsLunch from './KidsItems/KidsLunch';

function Kids() {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className='kidsMain'>
            <ScrollToTop />
            <Submenu />
            <h1 className='menuTitle'>Kids Menu</h1>
            <KidsBreakfast />
            <KidsLunch />
        </div>
    )
}

export default Kids