import React from 'react';
import SinglePancake from '../../images/1Pancake.jpeg';
import DoublePancake from '../../images/2Pancakes.jpg';
import TriplePancakes from '../../images/3Pancakes.jpg';
import SingleFT from '../../images/1pcFT.jpg';
import DoubleFT from '../../images/2pcFT.jpg';

function BreakfastSweets() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Breakfast Sweets</h1>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={SinglePancake} alt='Single Pancake' />
                        <h1>Single Pancake</h1>
                        <h2>$4.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={DoublePancake} alt='Double Pancakes' />
                        <h1>Double Pancakes</h1>
                        <h2>$6.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={TriplePancakes} alt='Triple Pancakes' />
                        <h1>Triple Pancakes</h1>
                        <h2>$9.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={SingleFT} alt='Single French Toast' />
                        <h1>Single French Toast</h1>
                        <h2>$4.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={DoubleFT} alt='Double French Toast' />
                        <h1>Double French Toast</h1>
                        <h2>$6.99</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BreakfastSweets