import React from 'react';
import HamBagel from '../../images/bagelHamSwiss.jpg';
import HoneysBagel from '../../images/HoneysBagel.jpg';
import VeggieBagel from '../../images/bagelVeggie.jpg';
import GarbageBagel from '../../images/garbageBagel.jpg';
import Addon from './Addon';

function Bagel() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Honey's Bagels</h1>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={HamBagel} alt='Ham & Swiss Bagel' />
                        <h1 className='bagels'>Ham & Swiss Bagel</h1>
                        <p className='bagel'>Toasted bagel with swiss cheese & ham</p>
                        <h2>$7.41</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={HoneysBagel} alt='Honeys Bagel' />
                        <h1 className='bagels'>Honey's Bagel</h1>
                        <p className='bagel'>Lettuce, bacon, egg, cheese & mayo</p>
                        <h2>$8.15</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={VeggieBagel} alt='Veggie Bagel' />
                        <h1 className='bagels'>Veggie Bagel</h1>
                        <p className='bagel'>Lettuce, tomatoes, green peppers, cheese & ranch dressing</p>
                        <h2>$7.73</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={GarbageBagel} alt='Garbage on a Bagel' />
                        <h1 className='bagels'>Garbage on a Bagel</h1>
                        <p className='bagel'>Lettuce, tomatoes, bacon, ham, cheese & mayo</p>
                        <h2>$7.94</h2>
                    </div>
                </div>
                <Addon />
            </div>
        </div>
    )
}

export default Bagel