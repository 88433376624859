import React from 'react';
import HoneysCheddar from '../../images/honeysCheddar.jpg';
import CornedBeef from '../../images/cornBeef.jpg';
import Reuben from '../../images/reuben.jpg';
import BeefOnBun from '../../images/beefOnBun.jpg';
// import TurkeyClub from '../../images/garbageBagel.jpg';
// import AllInCombo from '../../images/combo2.jfif';
import Addon from './Addon';

function Sandwiches() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Sandwiches</h1>
                <p>Gluten Free Bread is Available</p>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={HoneysCheddar} alt='Honeys Cheddar' />
                        <h1>Honey's Cheddar</h1>
                        <p className='sandwiches'>Grilled cheese with bacon & tomatoes</p>
                        <h2>$9.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={CornedBeef} alt='Corned Beef' />
                        <h1>Corned Beef</h1>
                        <p className='sandwiches'>Corned beef piled high on freshly grilled rye bread</p>
                        <h2>$10.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={Reuben} alt='Grilled Reuben' />
                        <h1>Grilled Reuben</h1>
                        <p className='sandwiches'>Corned beef on freshly grilled rye bread, topped with swiss cheese, sauerkraut & 1000 island dressing</p>
                        <h2>$11.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={BeefOnBun} alt='Beef On A Bun' />
                        <h1>Beef On A Bun</h1>
                        <p className='sandwiches'>
                            <span className='gl'>Pull out the napkins!</span>
                            Thinly shaved roast beef piled high on a toasted ciabatta bun, topped with sauteed onions & gravy</p>
                        <h2>$13.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Turkey Club on a Bun' />
                        <h1>Turkey Club on a Bun</h1>
                        <p className='sandwiches'>Sliced smoked turkey topped with lettuce, tomatoes, cheese & mayo, served on a toasted ciabatta bun</p>
                        <h2>$13.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Tuna Melt' />
                        <h1>Tuna Melt</h1>
                        <p className='sandwiches'>Traditional grilled cheese sandwich topped with our homemade tuna salad & tomatoes on rye</p>
                        <h2>$13.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Tuna Salad' />
                        <h1>Tuna salad sandwich</h1>
                        <p className='sandwiches'>Lettuce & tomatoes topped with our homemade tuna salad on rye</p>
                        <h2>$11.99</h2>
                    </div>
                </div>
                <Addon />
            </div>
        </div>
    )
}

export default Sandwiches