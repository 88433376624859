import React from 'react';
import FriedEgg from '../../images/FriedEgg.jpg';
import FriedEggwMeat from '../../images/FriedEggMeat.jpg';
import BLT from '../../images/BLT.jpg';
import PeamealOnBun from '../../images/PeamealOnBun.jpg';
import Western from '../../images/WesternSW.jpg';

function BreakfastSW() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Breakfast Sandwiches</h1>
            </div>
            <div className='changeMain'>
                <p className='change'>Add small side of wedges for $3.69</p>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={FriedEgg} alt='Fried Egg' />
                        <h1>Fried Egg</h1>
                        <p>2 fried eggs served on a toast</p>
                        <h2>$5.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={FriedEggwMeat} alt='Fried Egg & Meat' />
                        <h1>Fried Egg & Meat</h1>
                        <p>2 fried eggs with your choice of bacon OR ham</p>
                        <h2>$7.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={BLT} alt='BLT' />
                        <h1>BLT</h1>
                        <p>bacon, lettuce, tomato & mayo</p>
                        <h2>$8.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={PeamealOnBun} alt='Peameal on a Bun' />
                        <h1>Peameal on a Bun</h1>
                        <p>peameal bacon, lettuce, tomato, cheese & mayo</p>
                        <h2>$10.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={Western} alt='Toasted Western' />
                        <h1>Toasted Western</h1>
                        <p>2 eggs, ham, green peppers & onions</p>
                        <h2>$11.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={''} alt='Vegetarian Western' />
                        <h1>Vegetarian Western</h1>
                        <p>2 eggs, onions, green peppers & mushrooms</p>
                        <h2>$10.99</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BreakfastSW