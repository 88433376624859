import React from 'react';
import CheeseSticks from '../../images/cheeseSticks.jpg';
import DeepFriedPickles from '../../images/deepFriedPickles.jpg';
import SpringRolls from '../../images/springRolls.jpg';
import Nacho from '../../images/nachos.jpg';
import Wings from '../../images/honeysWings.jpg';
import ChipDip from '../../images/chipDip.jpg';

function Appetizers() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Appetizers</h1>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={CheeseSticks} alt='Cheese Sticks' />
                        <h1>Cheese Sticks</h1>
                        <p>Breaded mozzarella sticks served with marinara sauce</p>
                        <h2>$8.53</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={DeepFriedPickles} alt='Deep Fried Pickles' />
                        <h1>Deep Fried Pickles</h1>
                        <p>Delicious tangy dill pickles battered and fried, served with ranch dressing</p>
                        <h2>$9.28</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={SpringRolls} alt='Spring Rolls' />
                        <h1>Spring Rolls</h1>
                        <p>Carrots, shiitake mushroom, cabbage, green onion, cilantro, and vermicelli noodles</p>
                        <h2>$6.94</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Appetizer Platter' />
                        <h1>Appetizer Platter</h1>
                        <p>Enjoy the combination of our signature lattice fries, sweet potato fries & onion rings served with dipping sauce</p>
                        <h2>$15.11</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={Nacho} alt='Nacho Platter' />
                        <h1>Nacho Platter</h1>
                        <p>Nachos covered with cheese, red & green peppers, served with salsa & sour cream
                            <span className='gl'>Add Bacon : $0.99</span>
                            <span className='gl'>Gluten Free Platter is Available</span>
                        </p>
                        <h2>$15.11</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={Wings} alt='Honeys Wings' />
                        <h1>Honey's Wings</h1>
                        <p>1 lb. breaded wings, served in your favorite sauce with carrots and celery
                            <span className='gl'>(mild, medium, hot, honey garlic, BBQ)</span>
                        </p>
                        <h2>$14.58</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={ChipDip} alt='Chip & Dip' />
                        <h1>Chip & Dip</h1>
                        <p>Basket of lattice fries served with french onion dip</p>
                        <h2>$9.96</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Appetizers