import React from 'react';

function KidsBreakfast() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Kids Breakfast</h1>
            </div>
            <div className='changeMain'>
                <p className='change'>All choices served with apple or orange juice</p>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={''} alt='1 Egg Breakfast' />
                        <h1>1 Egg Breakfast</h1>
                        <p>1 egg, meat slice, toast & your choice of homefries OR fruits</p>
                        <h2>$9.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={''} alt='2 Egg Omelette' />
                        <h1>2 Egg Omelette</h1>
                        <p>2 eggs cheese omelette w/ toast & fruits</p>
                        <h2>$9.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={''} alt='Pancakes' />
                        <h1>Pancakes</h1>
                        <p>2 pancakes w/ fruits & meat slice</p>
                        <h2>$9.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={''} alt='French Toast' />
                        <h1>French Toast</h1>
                        <p>2 pc french toast w/ fruits & meat slice</p>
                        <h2>$9.99</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KidsBreakfast