import React from 'react';
import Soup from '../../images/largeSoup.jpg';
import SoupSalad from '../../images/soupAndSalad.jpg';
import Caesar from '../../images/largeCaesar.jpg';
import Greek from '../../images/largeGreek.jpg';
import Garden from '../../images/largeGarden.jpg';

function SoupSalads() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Soups & Salads</h1>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={Soup} alt='Large Soup' />
                        <h1>Soup of the Day</h1>
                        <p>Served with <br /> your choice 
                            <br /> of white <br /> OR <br /> brown
                            toast
                        </p>
                        <h2>$7.41</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={SoupSalad} alt='Soup & Salad Combo' />
                        <h1>Soup & Salad Combo</h1>
                        <p>Small soup and your choice of small salad</p>
                        <h2>$10.44</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={Caesar} alt='Caesar Salad' />
                        <h1>Caesar Salad</h1>
                        <p>Lettuce topped with real bacon bits & homemade crotons, tossed with fresh parmesan cheese in a creamy caesar dressing
                            <br />
                            <br />
                            <span className='gl'>Add Chicken : $3.17</span>
                        </p>
                        <h2>$11.18</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={Greek} alt='Greek Salad' />
                        <h1>Greek Salad</h1>
                        <p>Lettuce topped with red bermuda onions, olives, green peppers, tomatoes, cucumber, feta cheese & greek dressing
                            <br />
                            <br />
                            <span className='gl'>Add Chicken : $3.17</span>
                        </p>
                        <h2>$11.18</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={Garden} alt='Garden Salad' />
                        <h1>Garden Salad</h1>
                        <p>Lettuce topped with onions, green & red peppers, tomatoes, cucumber, cranberries & your choice of dressing on the side
                            <br />
                            <span className='gl'>Add Chicken : $3.17</span>
                        </p>
                        <h2>$11.18</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SoupSalads