import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import './Navbar.css';
import Dropdown from './Dropdown';
import { BsFillPeopleFill } from 'react-icons/bs';
import { FaHome } from 'react-icons/fa';
import { MdRestaurantMenu } from 'react-icons/md';
import { MdPhoneInTalk } from 'react-icons/md';

function Navbar() {

    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [colorChange, setColorchange] = useState(false);

    const changeNavbarColor = () =>{
        if(window.scrollY >= 80) {
        setColorchange(true);
        }
        else{
        setColorchange(false);
        }
    };

    const [path, setPath] = useState('')

    console.log(path)

    useEffect (() => {
        setPath(window.location.pathname)
    },[path])

    useEffect(() => {
        if(path === '/contactus') {
            setColorchange(true)
        } else {setColorchange(false)}
    }, [path])

    window.addEventListener('scroll', changeNavbarColor);

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    const onMouseEnter = () => {
        if(window.innerWidth < 960) {
            setDropdown(false)
        } else {
            setDropdown(true)
        }
    }

    const onMouseLeave = () => {
        if(window.innerWidth < 960) {
            setDropdown(false)
        } else {
            setDropdown(false)
        }
    }

    return (
        <>
            <nav className={colorChange ? 'navbar colorChange' : 'navbar'}>
                <Link to='/' className={path === '/' ? 'noLogoNav' : 'navbar-logo'}>
                    <div className='logo'></div>
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link 
                            to='/' 
                            className='nav-links' 
                            onClick={(e) => {
                                closeMobileMenu()
                                setPath(e.target.value)
                            }}
                        >
                            <FaHome className='icon' />
                            Home
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link 
                            to='/about' 
                            className='nav-links' 
                            onClick={(e) => {
                                closeMobileMenu()
                                setPath(e.target.value)
                            }}
                        >
                            <BsFillPeopleFill className='icon' />
                            About Us
                        </Link>
                    </li>
                    <li className='nav-item' 
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        <Link 
                            to='/menu' 
                            className='nav-links' 
                            onClick={(e) => {
                                closeMobileMenu()
                                setPath(e.target.value)
                            }}
                        >
                            <i className='fas fa-caret-down' /> 
                            <MdRestaurantMenu className='icon' />
                            Menu
                        </Link>
                        {dropdown && <Dropdown />}
                    </li>
                    <li className='nav-item'>
                        <Link 
                            to='/contactus' 
                            className='nav-links' 
                            onClick={(e) => {
                                closeMobileMenu()
                                setPath(e.target.value)
                            }}
                        >
                            <MdPhoneInTalk className='icon' />
                            Contact Us
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default Navbar

