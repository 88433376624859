import React from 'react';
import Tenders from '../../images/tenders.jfif';
import HotBurger from '../../images/hotBurger.jpeg';
import Fish from '../../images/fullFish.jpg';

function Dinner() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Honey's Dinner Classics</h1>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={Tenders} alt='Chicken Tender Dinner' />
                        <h1 className='fish'>Chicken Tender Dinner</h1>
                        <p className='dinner'>Our breaded chicken tenders served with our classic golden fries & Plum sauce</p>
                        <h2>$18.20</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={HotBurger} alt='Hot Hamburger' />
                        <h1 className='fish'>Hot Hamburger</h1>
                        <p className='dinner'>Our open faced burger is served on bread covered with sauteed onions & gravy, served with coleslaw & fries</p>
                        <h2>$17.09</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={Fish} alt='Fish & Chips' />
                        <h1 className='fish'>Fish & Chips</h1>
                        <p className='dinner'>Our crispy 10 oz haddock served with homemade coleslaw, lemon, tartar sauce & our classic golden fries</p>
                        <h2>$27.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Chicken Stir-Fry' />
                        <h1 className='fish'>Chicken Stir-Fry</h1>
                        <p className='dinner'>Mixed vegetables & grilled chicken fried in your choice of spicy thai sauce or plum sauce, served on a plate of rice</p>
                        <h2>$21.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Roast Beef Dinner' />
                        <h1 className='fish'>Roast Beef Dinner</h1>
                        <p className='dinner'>Sliced roast beef, smothered in gravy & sautéed onions, served with a side of mashed potatoes & homemade coleslaw</p>
                        <h2>$21.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Meatloaf Dinner' />
                        <h1 className='fish'>Meatloaf Dinner</h1>
                        <p className='dinner'>Sliced homemade meatloaf, smothered in gravy & sautéed onions. Served with a side of mashed potatoes & homemade coleslaw</p>
                        <h2>$19.99</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dinner