import React from 'react';
import HoneysOm from '../../images/HoneysOmelette.jpg';
import VeggieOm from '../../images/VeggieOmelette.jpg';
import BeefOm from '../../images/BeefOmelette.jpg';
import CheeseOm from '../../images/CheeeseOmelette.jpg';
import WesternOm from '../../images/WesternOmelette.jpg';
import CanadianOm from '../../images/CanadianOm.jpg';

function BreakfastOmelettes() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Breakfast Omelettes</h1>
            </div>
            <div className='changeMain'>
                <p className='change'>All omelettes served with toast <br />
                    Add small side of wedges for $3.69</p>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={HoneysOm} alt='Honeys Omelette' />
                        <h1>Honey's Omelette</h1>
                        <p>3 eggs mixed with bacon, ham & sausage topped with cheese blend</p>
                        <h2>$17.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={BeefOm} alt='Loaded Meat Omelette' />
                        <h1>Loaded Meat Omelette</h1>
                        <p>3 eggs mixed with ham, roast beef & cheese blend</p>
                        <h2>$17.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={CheeseOm} alt='Cheese Omelette' />
                        <h1>Cheese Omelette</h1>
                        <p>3 eggs stuffed with your choice of 3 cheese blend OR feta cheese</p>
                        <h2>$13.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={CanadianOm} alt='Canadian Omelette' />
                        <h1>Canadian Omelette</h1>
                        <p>3 eggs mixed with cheese & peameal bacon</p>
                        <h2>$14.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={VeggieOm} alt='Veggie Omelette' />
                        <h1>Veggie Omelette</h1>
                        <p>3 eggs mixed with mushrooms, peppers, tomatoes & onions</p>
                        <h2>$14.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={WesternOm} alt='Western Omelette' />
                        <h1>Western Omelette</h1>
                        <p>3 eggs mixed with ham, green peppers & onions</p>
                        <h2>$14.49</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BreakfastOmelettes