import React from 'react';
// import Combo from '../../images/combo1.jfif';
import ClassicBurger from '../../images/burgerClassic.jfif';
import RancherBurger from '../../images/bltBurger.jfif';
import ReubenBurger from '../../images/BBQBurger.jfif';
import CanadianBurger from '../../images/canadianBurger.jpg';
import ChickenBurger from '../../images/chicken-burger-recipe-500x500.jpg';
import Addon from './Addon';
// import CountryBurger from '../../images/combo1.jfif';
// import DoubleBurger from '../../images/combo1.jfif';
// import MegaBurger from '../../images/combo1.jfif';
// import VeggieBurger from '../../images/combo1.jfif';
// import SwissBurger from '../../images/combo1.jfif';
// import AioliBurger from '../../images/combo1.jfif';

function Burgers() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Honey's Burgers</h1>
                <p>Gluten Free Buns are Available</p>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={ClassicBurger} alt='Burger Classic' />
                        <h1>Burger Classic</h1>
                        <p className='burger'>Our flavour bursting burger is topped with lettuce, tomatoes, onions, pickles & mayo</p>
                        <h2>$14.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Mega Burger' />
                        <h1>Mega Burger</h1>
                        <p className='burger'>Honey's classic burger patty topped with lettuce, tomatoes, onions, mayo, fried egg, cheese & bacon</p>
                        <h2>$16.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Ranchers Burger' />
                        <h1>Rancher's Burger</h1>
                        <p className='burger'>honey's classic burger, lettuce, provolone cheese, crispy onions, topped with a deep fried pickle & ranch dressing</p>
                        <h2>$15.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Reuben Burger' />
                        <h1>Reuben Burger</h1>
                        <p className='burger'>Honey’s burger topped with swiss cheese, thousand island, sauerkraut & sliced corned beef </p>
                        <h2>$15.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={CanadianBurger} alt='Canadian Burger' />
                        <h1>Canadian Burger</h1>
                        <p className='burger'>Honey's Canadian style burger is topped with lettuce, tomatoes, mayo, peameal bacon & cheese</p>
                        <h2>$15.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Swiss Burger' />
                        <h1>Swiss Burger</h1>
                        <p className='burger'>Our burger patty is topped with lettuce, tomatoes, mushrooms, mayo & Swiss cheese</p>
                        <h2>$14.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Aoili Burger' />
                        <h1>Aoili Burger</h1>
                        <p className='burger'>Our signature burger topped with lettuce, tomatoes, cheese, onion rings & covered with spicy aioli sauce</p>
                        <h2>$15.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Country Burger' />
                        <h1>Country Burger</h1>
                        <p className='burger'>Honey's burger covered with American cheese, chipotle sauce, fried egg, tomato, bacon & topped with a hashbrown </p>
                        <h2>$15.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Double Burger' />
                        <h1>Double Burger</h1>
                        <p className='burger'>2 of our grandee burger patties topped with lettuce, tomatoes, onions, pickles & mayo</p>
                        <h2>$19.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={ChickenBurger} alt='Crispy Chicken Burger' />
                        <h1>Crispy Chicken Burger</h1>
                        <p className='burger'>Breaded chicken burger topped with mayo, lettuce & tomatoes</p>
                        <h2>$14.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Veggie Burger' />
                        <h1>Veggie Burger</h1>
                        <p className='burger'>Veggie patty topped with lettuce, tomatoes, pickles, onions & mayo</p>
                        <h2>$13.99</h2>
                    </div>
                </div>
                <Addon />
            </div>
        </div>
    )
}

export default Burgers