import React from 'react';

function KidsLunch() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Kids Lunch</h1>
                <p>All Items Bellow Served with Choice of Fries OR Veggie Sticks OR Caesar Salad & Apple juice OR Orange juice</p>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={''} alt='Chicken Wrap' />
                        <h1>Chicken Wrap</h1>
                        <p className='kidsLunch'>Grilled chicken, cheese, lettuce & ranch dressing served with fries OR veggie sticks</p>
                        <h2>$11.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={''} alt='Cheese Quesadilla' />
                        <h1>Cheese Quesadilla</h1>
                        <p className='kidsLunch'>Cheese mix melted inside a 12' tortilla</p>
                        <h2>$11.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={''} alt='Chicken Nuggets' />
                        <h1>Chicken Nuggets</h1>
                        <p className='kidsLunch'>Real chicken nuggets</p>
                        <h2>$11.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={''} alt='Grilled Cheese S/W' />
                        <h1>Grilled Cheese S/W</h1>
                        <p className='kidsLunch'>Grilled cheese s/w with real cheddar cheese</p>
                        <h2>$11.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={''} alt='Plain Burger' />
                        <h1>Plain Burger</h1>
                        <p className='kidsLunch'>Our classic burger patty served on a bun</p>
                        <h2>$11.49</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KidsLunch