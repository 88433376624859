import React from 'react';
import ClassicFries from '../../images/fries.jfif';
import SweetPotatoFries from '../../images/sweetPotato.jfif';
import OnionRings from '../../images/onionRings.jfif';
import Gravy from '../../images/gravy.jfif';
import Poutine from '../../images/poutine.jfif';

function Sides() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Sides</h1>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={ClassicFries} alt='Classic Fries' />
                        <h1 className='side'>Fries</h1>
                        <h2>$6.79</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={SweetPotatoFries} alt='Sweet Potato Fries' />
                        <h1 className='side'>Sweet Potato Fries</h1>
                        <h2>$7.79</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={OnionRings} alt='Onion Rings' />
                        <h1 className='side'>Onion Rings</h1>
                        <h2>$7.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={Gravy} alt='Gravy' />
                        <h1 className='side'>Gravy</h1>
                        <h2>$1.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={Poutine} alt='Poutine' />
                        <h1 className='side'>Update Any Side to Poutine</h1>
                        <h2>$2.99 Extra</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sides