import React from 'react';
import b_Special from '../../images/b_Special.jpg';
import c_Special from '../../images/c_Special.jpg';
import l_Special from '../../images/l_Special.jpg';
import eps from '../../images/e_p_s.jpeg';
import efs from '../../images/e_ft_s.jpg';

function EggBreakfast() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Egg Breakfast</h1>
            </div>
            <div className='changeMain'>
                <p className='change'>Substitute Rye or Multigrain Toast for $0.99 <br />
                    Substitute Gluten Free Toast for $1.75 <br />
                    Egg styles: Over Easy/Medium/Hard, Sunny Side Up OR Scrambled</p>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={b_Special} alt='Breakfast Special' />
                        <h1>Breakfast Special</h1>
                        <p>2 eggs, bacon OR ham OR sausage, toast & seasoned wedges</p>
                        <h2>$13.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={c_Special} alt='Carpenters Special' />
                        <h1>Carpenter's Special</h1>
                        <p>3 eggs, peameal bacon, toast & seasoned wedges</p>
                        <h2>$17.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={l_Special} alt='Landscapers Specials' />
                        <h1>Landscaper's Specials</h1>
                        <p>3 eggs, bacon, ham & sausage, toast & seasoned wedges</p>
                        <h2>$18.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={efs} alt='Early Bird French-Toast' />
                        <h1>Early Bird French-Toast</h1>
                        <p>2 pc of french-toast, 2 eggs, bacon OR ham OR sausage</p>
                        <h2>$16.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={eps} alt='Early Bird Pancakes' />
                        <h1>Early Bird Pancakes</h1>
                        <p>2 pancakes, 2 eggs, bacon OR ham OR sausage</p>
                        <h2>$15.49</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EggBreakfast