import React from 'react';
import '../../App.css';

function About() {
  return (
    <div className='aboutMain'>
      <div className='aboutSecondary'>
        <h1 className='aboutTitle'><span>A</span>bout Us</h1>
        <h2>We are a family owned restaurant located in the heart of the Kawartha Lakes.</h2>
        <br/>
        <h2>We pride ourselves on great food and exceptional service.</h2>
      </div>
      <div className='videos'>
        <video className='video' width="300" height="150" controls >
          <source src={require('../images/video1.mp4')} type="video/mp4" autoPlay='true' />
        </video>
      </div>
    </div>
  )
}

export default About