import React from 'react';
// import ClassicWrap from '../../images/bltBurger.jfif';
// import UltimateWrap from '../../images/BBQBurger.jfif';
// import HotSpicyWrap from '../../images/canadianBurger.jpg';
// import CaesarWrap from '../../images/chickenBurger.jfif';
// import FalafelWrap from '../../images/combo1.jfif';
// import TurkeyWrap from '../../images/combo1.jfif';
// import GreekWrap from '../../images/combo1.jfif';
// import VeggieWrap from '../../images/combo1.jfif';
// import PorkWrap from '../../images/combo1.jfif';
// import SteakWrap from '../../images/combo1.jfif';
import Combo from '../../images/combo1.jfif';
import Addon from './Addon';

function Wraps() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Honey's Wraps</h1>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Honeys Classic Wrap' />
                        <h1>Honey's Classic Wrap</h1>
                        <p className='wraps'>Grilled chicken, lettuce, tomatoes, cheese & Ranch dressing</p>
                        <h2>$14.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='The Ultimate Wrap' />
                        <h1>The Ultimate Wrap</h1>
                        <p className='wraps'>Grilled chicken, lettuce, tomatoes, onions, cheese, fries & Ranch dressing wraped in 2 tortillas</p>
                        <h2>$21.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Hot & Spicy Wrap' />
                        <h1>Hot & Spicy Wrap</h1>
                        <p className='wraps'>Crispy chicken, lettuce, tomatoes, cheese & spicy tai dressing</p>
                        <h2>$14.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Chicken Caesar Wrap' />
                        <h1>Chicken Caesar Wrap</h1>
                        <p className='wraps'>Crispy chicken, caesar salad & BBQ sauce</p>
                        <h2>$14.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Falafel Wrap' />
                        <h1>Falafel Wrap</h1>
                        <p className='wraps'>Falafel, lettuce, tomatoes, red onions & tzatziki sauce</p>
                        <h2>$14.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Greek Wrap' />
                        <h1>Greek Wrap</h1>
                        <p className='wraps'>Grilled chicken, lettuce, tomatoes, onions, feta cheese & Greek dressing</p>
                        <h2>$15.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Veggie Wrap' />
                        <h1>Veggie Wrap</h1>
                        <p className='wraps'>Assorted vegetables, cheese & Ranch dressing</p>
                        <h2>$13.99</h2>
                    </div>
                </div>
                <Addon />
            </div>
        </div>
    )
}

export default Wraps