import React, { useLayoutEffect} from 'react';
import ScrollToTop from '../ScrollToTop';
import './Breakfast.css';
import Submenu from '../SubMenu';
import EggBreakfast from './BreakfastItems/EggBreakfast';
import BreakfastWraps from './BreakfastItems/BreakfastWraps';
import BreakfastOmelettes from './BreakfastItems/BreakfastOmelettes';
import BreakfastSW from './BreakfastItems/BreakfastSW';
import BreakfastSweets from './BreakfastItems/BreakfastSweets';
import BreakfastSides from './BreakfastItems/BreakfastSides';

function Breakfast() {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className='breakfastMain'>
            <ScrollToTop />
            <Submenu />
            <h1 className='menuTitle'>Breakfast Menu</h1>
            <EggBreakfast />
            <BreakfastWraps />
            <BreakfastOmelettes />
            <BreakfastSW />
            <BreakfastSweets />
            <BreakfastSides />
        </div>
    )
}

export default Breakfast