export const MenuItems = [
    {
        title: 'Breakfast',
        path: '/breakfast',
        cName: 'dropdown-link'
    }, 
    {
        title: 'Lunch/Dinner',
        path: '/lunch',
        cName: 'dropdown-link'
    },
    {
        title: 'Kids',
        path: '/kids',
        cName: 'dropdown-link'
    }
]