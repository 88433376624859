import React from 'react';
import Combo from '../../images/combo1.jfif';

    function Addon() {
    return (
        <div className='comboFile'>
            <div className='box1'>
                <div className='text2'>
                    <img className='imgTag' src={Combo} alt='Make It A Combo' />
                    <h1>Make It A Combo</h1>
                    <p className='sandwiches'>Get a drink 
                        <br/>
                        (coffee, tea, pop) 
                        <span className='gl'>AND</span>
                        Choose between small fries OR small soup</p>
                    <h2>$7.49</h2>
                </div>
            </div>
            <div className='box1'>
                <div className='text2'>
                    <img className='imgTag' src={''} alt='All In Combo' />
                    <h1>All In Combo</h1>
                    <p className='sandwiches'>Get a drink (coffee, tea, pop) & small fries & small soup</p>
                    <h2>$12.49</h2>
                </div>
            </div>
        </div>
    )
}

export default Addon