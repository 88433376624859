import React from 'react';
import Burrito from '../../images/Burrito.jpg';
import Kawartha from '../../images/KawarthWrap.jpg';
import Canadian from '../../images/CanadianWrap.jpg';

function BreakfastWraps() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Breakfast Wraps</h1>
            </div>
            <div className='changeMain'>
                <p className='change'>Add small side of wedges for $3.69</p>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={Burrito} alt='Bee Burrito' />
                        <h1>Bee Burrito</h1>
                        <p>3 eggs mixed with bacon, peppers, onions & cheese</p>
                        <h2>$11.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={Kawartha} alt='Kawartha Wrap' />
                        <h1>Kawartha Wrap</h1>
                        <p>3 eggs mixed with onions, mushrooms, green peppers & cheese</p>
                        <h2>$9.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={Canadian} alt='Canadian Wrap' />
                        <h1>Canadian Wrap</h1>
                        <p>3 eggs mixed with sausage, onions & cheese</p>
                        <h2>$10.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={''} alt='Woodview Burrito' />
                        <h1>Woodview Burrito</h1>
                        <p>3 eggs mixed with bacon, sausage, hashbrown, cheese & chipotle sauce</p>
                        <h2>$13.49</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BreakfastWraps