import wings from '../images/wings.jpg'
import burger from '../images/BaconCheeseburger2.jpg'
import nachos from '../images/nachos.jpg'
import tenders from '../images/tenders.jfif'
import Montreal from '../images/MontrealSandwich.jpg'

export const SliderData = [
    {
      image:
        burger
    },
    {
      image:
        nachos
    },
    {
      image:
        tenders
    },
    {
      image:
        wings
    },
    {
      image:
        Montreal
    }
  ];