import React from 'react';
import HoneysCheddar from '../../images/honeysCheddar.jpg';
import CornedBeef from '../../images/cornBeef.jpg';
import Reuben from '../../images/reuben.jpg';
import BeefOnBun from '../../images/beefOnBun.jpg';
// import TurkeyClub from '../../images/garbageBagel.jpg';
import Combo from '../../images/combo1.jfif';
// import AllInCombo from '../../images/combo2.jfif';
import { GiBroccoli } from "react-icons/gi";

function Quesadillas() {
    return (
        <div className='titles'>
            <div className='quesaTitle'>
                <h1>Quesadillas</h1>
                <p>Add large fries for $6.79 OR Make Combo with drink & fries for $7.49</p>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Grilled Chicken Quesadilla' />
                        <h1>Grilled Chicken Quesadilla</h1>
                        <p className='quesadillas'>Grilled seasoned chicken breast with cheese & peppers</p>
                        <h2>$17.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='BBQ Pulled Pork Quesadilla' />
                        <h1>BBQ Pulled Pork Quesadilla</h1>
                        <p className='quesadillas'>Juicy slow cooked BBQ pulled pork & cheese</p>
                        <h2>$18.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Crispy Chicken Quesadilla' />
                        <h1>Crispy Chicken Quesadilla</h1>
                        <p className='quesadillas'>Breaded crispy chicken with cheese & peppers</p>
                        <h2>$17.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text2'>
                        <img className='imgTag' src={''} alt='Vegetarian Quesadilla' />
                        <h1>Vegetarian
                            <GiBroccoli />
                            Quesadilla
                        </h1>
                        <p className='quesadillas'>Mushrooms, peppers, onions & cheese</p>
                        <h2>$17.49</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quesadillas