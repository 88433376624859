import React from 'react';
import Wedges from '../../images/Wedges.jpg';
import HashBrowns from '../../images/HashBrowns.jpg';
import Cheese from '../../images/Cheese.jpg';
import Toast from '../../images/Toast.jpg';
import Tomatoes from '../../images/Tomatoes.jpg';
import Egg from '../../images/1_Egg.jpg';
import Peameal from '../../images/Peameal.jpg';
import Meat from '../../images/Bacon_ham_sausage.jpg';

function BreakfastSides() {
    return (
        <div className='titles'>
            <div className='breakfastTitle'>
                <h1>Breakfast Sides</h1>
            </div>
            <div className='grid'>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={Wedges} alt='Seasoned Wedges' />
                        <h1>Seasoned Wedges</h1>
                        <h2>$6.29</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={HashBrowns} alt='2pc Hash Browns' />
                        <h1>2pc Hash Browns</h1>
                        <h2>$4.29</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={Cheese} alt='Extra Cheese' />
                        <h1>Extra Cheese</h1>
                        <h2>$1.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={Toast} alt='Toast (2 slices)' />
                        <h1>Toast (2 slices)</h1>
                        <h2>$2.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={Tomatoes} alt='Fresh Cut Tomatoes' />
                        <h1>Fresh Cut Tomatoes</h1>
                        <h2>$2.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={Egg} alt='Extra Egg' />
                        <h1>Extra Egg</h1>
                        <h2>$2.49</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={Peameal} alt='Peameal Bacon' />
                        <h1>Peameal Bacon</h1>
                        <h2>$4.99</h2>
                    </div>
                </div>
                <div className='box1'>
                    <div className='text1'>
                        <img className='imgTag' src={Meat} alt='Bacon OR Ham OR Sausage' />
                        <h1>Bacon OR Ham OR Sausage</h1>
                        <h2>$3.99</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BreakfastSides